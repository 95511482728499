import React, { Component, Suspense } from 'react'
import { Route, Routes, HashRouter } from 'react-router-dom'
import './scss/style.scss'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const DefaultLayout = React.lazy(() => import('../src/layout/DefaultLayout'))

const Login = React.lazy(() => import('../src/views/login.js'))
const PageNotFound = React.lazy(() => import('../src/views/pageNotFound'))
const UnavailablePageError = React.lazy(() => import('../src/views/unavailalePageError'))
const VerifyEmail = React.lazy(() => import('../src/views/verifyLogin'))

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/login" name="login page" element={<Login />} />
            <Route exact path="/verify-email" name="verify" element={<VerifyEmail />} />
            <Route exact path="/404" name="404 erroe page" element={<PageNotFound />} />
            <Route exact path="/500" name="500 erroe page" element={<UnavailablePageError />} />
            <Route path="*" name="Home Page" element={<DefaultLayout />} />
            <Route path="/order/updateOrder" name="not found" element={<PageNotFound />} />
            <Route
              path="/order/createorder/updateUploaded"
              name="not found"
              element={<PageNotFound />}
            />
          </Routes>
        </Suspense>
      </HashRouter>
    )
  }
}

export default App
